<template>
  <iFooter class="homeMapFooter">
    <iCard v-if="viewed" class="homeMapFooterCard" style="height: 155px; width: 500px">
      <p slot="title">
        {{ viewed.name }} ({{ viewed.company }})
        <iTooltip v-if="viewed.gps && viewed.gps.gps_age > 240"
          content="Verifique que el vehiculo no se encuentre en un galpón o subsuelo" placement="bottom"
          max-width="250">
          <span class="error">Posición GPS inválida</span>
        </iTooltip>
      </p>
      <p class="homeMapFooterCardItem" v-if="viewed.gps">
        <iIcon type="md-calendar" size="20" />
        <b>Ultimo reporte:</b>
        {{ gpsDate }} (hace {{ fmtDistanceToNow(this.viewed.gps.date) }})
        <span v-if="viewed.gps.t_elapsed > 900" class="error">Sin reporte actual</span>
      </p>
      <p class="homeMapFooterCardItem" v-if="viewed.lastMove">
        <iIcon type="md-calendar" size="20" />
        <b>Ultimo movimiento:</b> {{lastMoveDate}} (hace {{ fmtDistanceToNow(this.viewed.lastMove) }})
      </p>
      <div class="items-horizontal">
        <cardItem icon="md-speedometer" v-if="viewed.gps" label="Velocidad" :value="parseInt(viewed.gps.speed)"
          unit="km/h" />
        <cardItem icon="ios-key" v-if="engOn" label="Motor" :value="engOn" />
      </div>
      <cardItem icon="md-pin" v-if="viewed.gps" label="Dirección" :value="address" />
      <div class="items-horizontal">
        <p v-if="viewed.odom && viewed.dvcType === 'TRAX'" class="homeMapFooterCardItem">
          <iIcon type="md-speedometer" size="20" />
          <b>Odómetro GPS:</b>
          {{(viewed.odom.odom.toFixed(2))}} Kms
        </p>
        <p v-if="dashboardOdom" class="homeMapFooterCardItem">
          <iIcon type="md-speedometer" size="20" />
          <b>Odómetro Tablero:</b>
          {{dashboardOdom}} Kms
        </p>
      </div>
    </iCard>
    <iCard class="homeMapFooterCard" title="Info" dis-hover>
      <cardItem icon="ios-barcode" label="Patente" :value="viewed.plate" />
      <cardItem :icon="typeIcon" label="Tipo" :value="viewed.typeLabel" />
      <cardItem icon="info" label="Marca" :value="viewed.brand" />
      <cardItem icon="info" label="Modelo" :value="viewed.model" />
      <p v-if="viewed.speedLimit" class="homeMapFooterCardItem">
        <iIcon type="md-speedometer" size="20" color="red" />
        <b>Limite Vel.:</b>
        {{viewed.speedLimit}} Km/h
        <iTooltip content="Configurar límite de velocidad" placement="right">
          <iButton v-if="setSpeedLimit && viewed.dvcType === 'TRAX'" type="info" ghost shape="circle"
            :to="`/config/${viewed.object}`" icon="ios-settings" />
        </iTooltip>
      </p>
      <cardItem icon="info" label="Cons Prom" :value="viewed.consPro" unit="L/100Km" />
    </iCard>
    <div class="homeMapFooterCard" v-if="canDate || viewed.engTemp || viewed.temp || battDate || viewed.dallas">
      <iCarousel class="homeMapFooterCarousel" v-model="carouselValue" arrow="always">
        <iCarouselItem style="height: 155px; width: 300px" v-if="canDate">
          <div class="cardContainer">
            <iCard class="homeMapFooterCard" v-if="canDate" dis-hover>
              <p slot="title">CAN</p>
              <cardItem icon="md-calendar" label="Fecha" :value="canDate" />
              <cardItem v-if="canEngTemp" icon="md-thermometer" label="Temperatura motor" :value="canEngTemp" />
              <cardItem v-if="viewed.can.can_mileage" icon="md-speedometer" label="Odómetro"
                :value="parseInt(viewed.can.can_mileage)" unit="kms" />
              <cardItem v-if="viewed.can.can_taho" icon="md-speedometer" label="Tacómetro"
                :value="parseInt(viewed.can.can_taho)" unit="RPM" />
              <cardItem v-if="viewed.can.can_fls" icon="gas-station" label="Nivel Combustible"
                :value="viewed.can.can_fls" :unit="viewed.canConfig.can_fls_unit" />
            </iCard>
          </div>
        </iCarouselItem>
        <iCarouselItem style="height: 155px; width: 300px" v-if="viewed.engTemp || viewed.temp || viewed.dallas">
          <div class="cardContainer">
            <iCard class="homeMapFooterCard" dis-hover>
              <p slot="title">Extras</p>
              <cardItem v-if="viewed.engTemp" icon="md-thermometer" label="Protector de Motor"
                :value="tempValue(parseInt(viewed.engTemp.temp))" />
              <cardItem v-if="viewed.engTemp" icon="md-calendar" label="Fecha Prot Motor" :value="engProtDate" />
              <cardItem v-if="tempDate" icon="md-calendar" label="Fecha" :value="tempDate" />
              <cardItem v-if="viewed.temp && tempValue(viewed.temp.t1)" icon="md-thermometer" label="Temperatura"
                :value="tempValue(viewed.temp.t1)" />
              <cardItem v-if="viewed.temp && tempValue(viewed.temp.t2)" icon="md-thermometer" label="Temperatura 2"
                :value="tempValue(viewed.temp.t2)" />
              <cardItem v-if="viewed.temp && tempValue(viewed.temp.t3)" icon="md-thermometer" label="Temperatura 3"
                :value="tempValue(viewed.temp.t3)" />
              <cardItem v-if="viewed.dallas" icon="md-thermometer" label="Temperatura"
                :value="tempValue(viewed.dallas.temp)" />
            </iCard>
          </div>
        </iCarouselItem>
        <iCarouselItem style="height: 155px; width: 300px" v-if="battDate">
          <div class="cardContainer">
            <iCard class="homeMapFooterCard" title="Batería" dis-hover>
              <cardItem icon="md-calendar" label="Fecha" :value="battDate" />
              <cardItem v-if="deviceBatt" icon="md-battery-charging" label="Batería Equipo" :value="deviceBatt" />
              <cardItem icon="md-battery-charging" label="Batería Unidad" :value="objBatt" unit="v" />
            </iCard>
          </div>
        </iCarouselItem>
        <iCarouselItem style="height: 155px; width: 300px" v-if="viewed.fuelLevel">
          <div class="cardContainer">
            <iCard class="homeMapFooterCard" title="Nivel de Combustible" dis-hover>
              <cardItem icon="md-calendar" label="Fecha" :value="fuelLevelDate" />
              <cardItem icon="info" label="Combustible" :value="viewed.fuelLevel.level" unit="L" />
              <cardItem icon="md-thermometer" label="Temperatura" :value="viewed.fuelLevel.temp" unit="°C" />
            </iCard>
          </div>
        </iCarouselItem>
      </iCarousel>
    </div>
  </iFooter>
</template>

<script>
import { shortFmt, isValid, fmtDistanceToNow } from '@/util/dates'
import CardItem from './footer/CardItem'
import { nominatiRevGeocode } from '@/api'

const isNum = (n) => typeof n === 'number'

export default {
  data ()
  {
    return {
      fmtDistanceToNow,
      carouselValue: 0,
      address: this.viewed && this.viewed.dir
    }
  },
  components: { CardItem },
  props: {
    viewed: Object
  },
  computed: {
    outdatedReport ()
    {
      return (
        (this.viewed.gps.t_elapsed > 600 && this.viewed.gps.speed) ||
        this.viewed.gps.t_elapsed > 2100
      )
    },
    setSpeedLimit ()
    {
      return this.$store.state.login.user.setSpeedLim
    },
    gpsDate ()
    {
      return shortFmt(this.viewed.gps.date)
    },
    lastMoveDate ()
    {
      return shortFmt(this.viewed.lastMove)
    },
    battDate ()
    {
      return this.viewed.batts && this.viewed.batts.date
        ? shortFmt(this.viewed.batts.date)
        : this.viewed.adc && this.viewed.adc.date
          ? shortFmt(this.viewed.adc.date)
          : null
    },
    deviceBatt ()
    {
      return this.viewed.batts
        ? `${this.viewed.batts.bat1}v`
        : this.viewed.adc && this.viewed.adc.adc6
          ? this.viewed.adc.adc6
          : null
    },
    objBatt ()
    {
      return this.viewed.batts
        ? this.viewed.batts.bat2
        : this.viewed.pwrSup
          ? this.viewed.pwrSup
          : null
    },
    dashboardOdom ()
    {
      return this.viewed.can && this.viewed.can.can_mileage
        ? this.viewed.can.can_mileage
        : this.viewed.odomTablero ? this.viewed.odomTablero.toFixed(2)
          : null
    },
    tempDate ()
    {
      return this.viewed.temp
        ? shortFmt(this.viewed.temp.date)
        : this.viewed.dallas
          ? shortFmt(this.viewed.dallas.date)
          : null
    },
    engProtDate ()
    {
      return shortFmt(this.viewed.engTemp.date)
    },
    fuelLevelDate ()
    {
      return shortFmt(this.viewed.fuelLevel.date)
    },
    canDate ()
    {
      return this.viewed.can && isValid(this.viewed.can.date * 1000)
        ? shortFmt(this.viewed.can.date)
        : null
    },
    canEngTemp ()
    {
      return this.viewed.can && this.viewed.can.eng_temp
        ? this.tempValue(this.viewed.can.eng_temp)
        : null
    },
    engOn ()
    {
      return this.viewed.engOn === true ? 'Encendido' : this.viewed.engOn === false ? 'Apagado' : null
    },
    typeIcon ()
    {
      return this.viewed.type === 25
        ? 'truck'
        : this.viewed.type === 6
          ? 'pick-up'
          : this.viewed.type === 1
            ? 'hatchback'
            : this.viewed.type === 19
              ? 'car-utility'
              : this.viewed.type === 21
                ? 'dumper'
                : this.viewed.type === 30
                  ? 'delivery-truck'
                  : this.viewed.type === 31
                    ? 'sedan-car'
                    : 'md-truck'
    }
  },
  methods: {
    tempValue (temp)
    {
      if (isNum(temp)) return temp.toFixed(1) + '°C'
      return null
    },
    getAddress (lat, lng)
    {
      nominatiRevGeocode(lat, lng).then(res => { this.address = res.data.display_name })
    }
  },
  watch: {
    viewed (value, old)
    {
      if (value !== old) {
        this.carouselValue = 0
        if (this.viewed.gps.dir === '-') this.getAddress(this.viewed.gps.lat, this.viewed.gps.lng)
        else this.address = this.viewed.gps.dir
      }
    }
  }
}
</script>
