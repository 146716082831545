<template>
  <iLayout class="tdLayout">
    <appHeader />
    <iContent class="layoutContent">
      <iLayout class="homeLayout">
        <iSider width="280">
          <HomeTree class="treeContainer"/>
        </iSider>
        <iContent class="homeLayoutContent">
          <div class="mainMap" ref="map"></div>
          <homeMapFooter v-if="viewed" :viewed="viewed" />
        </iContent>
      </iLayout>
    </iContent>
  </iLayout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import AppHeader from '@/components/AppHeader'
import HomeMap from '@/maps/homeMap'
import HomeTree from '@/components/home/HomeTree'
import HomeMapFooter from '@/components/home/HomeMapFooter'

const { mapState } = createNamespacedHelpers('data')

export default {
  name: 'home',
  components: { AppHeader, HomeMapFooter, HomeTree },
  computed: {
    ...mapState({
      objects: st => st.objects,
      objectList: st => st.objectList,
      references: st => st.references,
      lastUpd: st => st.lastUpd
    }),
    user () { return this.$store.state.login },
    noCluster () { return this.$store.state.login.type !== 1 },
    viewed () { return this.$store.getters['homeView/getViewed'] },
    mapExtent () { return this.$store.state.homeView.mapExtent },
    baseLayer () { return this.$store.state.homeView.baseLayer }
  },
  methods: {
    onSetBaseLayer (id) {
      this.$store.dispatch('homeView/setBaseLayer', id)
    }
  },
  mounted () {
    this._map = HomeMap(this.$refs.map, this.objects, this.references, this.baseLayer, this.onSetBaseLayer, this.noCluster)
    this._map.onSelect(id => { this.$store.dispatch('homeView/setViewed', id) })
    if (this.mapExtent) this._map.map.fitExtent(this.mapExtent)
    console.log(this.user)
    this.$Notice.info({
      title: 'Aviso de falta de pago',
      desc: nodesc ? '' : 'Here is the notification description. Here is the notification description. '
    });
  },
  watch: {
    objectList (objs) { this._map && this._map.updateAll(this.objects) },
    references (refs) { this._map && this._map.updateReferences(refs) },
    lastUpd (obj) { this._map && obj.gps && this._map.update(obj) },
    viewed (value, old) {
      this._map.setViewed(value, old)
      if (!value) return
      if (!old || value.object !== old.object) this._map.animateTo(value)
    }
  },
  created () { this.$store.dispatch('startUpdate') },
  beforeDestroy () {
    this.$store.dispatch('homeView/setMapExtent', this._map.map.getExtent())
    this.$store.dispatch('stopUpdate')
    this._map && this._map.destroy()
  }
}
</script>
