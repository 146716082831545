<template>
  <div class="homeSider">
    <object-select class="homeTreeSelect" :value="object" @input="select" />
    <VScroll :rows="rows" :value="value" :height="32">
      <div slot-scope="row" v-bind="row" :class="row.class" @click="select(row.id)">
        <iDivider class="treeDivider" v-if="row.level === 1" orientation="left">{{row.label}}</iDivider>
        <span v-else class="treeItemSpan">
          <div class="treeItemDesc">
            <iIcon :type="row.icon" size="26"></iIcon>
            {{row.label}}
          </div>
          <div class="treeItemStatus">
            <iAvatar :src="error" class="treeWarningIcon" size="small" shape="square" v-if="row.error" />
            <iAvatar :src="warning" class="treeWarningIcon" size="small" shape="square" v-else-if="row.warning" />
            <iAvatar v-if="row.can" :src="can" size="small" shape="square" />
            <iAvatar :src="row.stateIcon" size="small" shape="square" />
          </div>
        </span>
      </div>
    </VScroll>
  </div>
</template>

<script>
import VScroll from '@/components/VScroll'
import ObjectSelect from '@/components/ObjectSelect'
import warning from '@/maps/icons/warning.svg'
import parking from '@/maps/icons/parking.svg'
import arrow from '@/maps/icons/arrow.svg'
import error from '@/maps/icons/error.svg'
import stopArrow from '@/maps/icons/stopArrow.svg'
import can from '@/assets/can-logo.png'
import { MIN_PARKING_TIME } from '@/util/constants'

const isNum = n => typeof n === 'number'
const fItem = (sel, objectData) => i => ({
  level: i.level,
  id: i.id,
  label: i.label,
  can: i.level !== 1 ? objectData[i.id]?.info.can : false,
  class: 'tree-item level-' + i.level + (sel && sel.object === i.id ? ' is-active' : ''),
  icon: i.level === 1 ? 'md-home'
    : i.type === 25 ? 'truck'
      : i.type === 6 ? 'pick-up'
        : i.type === 1 ? 'hatchback'
          : i.type === 19 ? 'car-utility'
            : i.type === 21 ? 'dumper'
              : i.type === 30 ? 'delivery-truck'
                : i.type === 31 ? 'sedan-car'
                  : 'md-truck',
  stateIcon: (i.speed ? arrow : i.lastMoveDiff > MIN_PARKING_TIME ? parking : stopArrow),
  warning: i.warning,
  error: i.error
})

export default {
  data ()
  {
    return {
      warning,
      parking,
      arrow,
      error,
      can
    }
  },
  components: { ObjectSelect, VScroll },
  computed: {
    items () { return this.$store.getters['homeView/getTree'] },
    sel () { return this.$store.getters['homeView/getViewed'] },
    object () { return this.$store.state.homeView.viewed },
    objectsData () { return this.$store.state.data.objectsData },
    rows () { return this.items.map(fItem(this.sel, this.objectsData)) },
    value ()
    {
      const i = this.items.findIndex(i => this.sel && i.id === this.sel.object)
      return i !== -1 ? i : null
    }
  },
  methods: {
    select (id)
    {
      isNum(id) && this.$store.dispatch('homeView/setViewed', id)
    }
  }
}
</script>
